<template>
  <div id="quotation-list">
    <!--    <van-nav-bar-->
    <!--      title="我的报价书"-->
    <!--      left-arrow-->
    <!--      @click-left="goBack"-->
    <!--    />-->
    <van-tabs color="#3967A2" title-active-color="#3967A2" background="#F8F8F9"
              title-inactive-color="#999999" line-width="24px" @click="tabChange">
      <van-tab v-for="(item,index) in tabTitle" :title="item" :key="index">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh(item)">

          <van-list
            v-if="list.length>0"
            v-model:loading="moreLoading"
            :finished="moreFinished"
            finished-text="没有更多了"
            :immediate-check="false"
            :offset="50"
            @load="onLoad(item)"
          >
            <div class="card" v-for="x in list" :key="x.engineeringId" @click="goDetail(x)">
              <div class="card-info">
                <div class="name">项目名称：{{ x.engineeringName }}</div>
              </div>
              <div class="card-foot">
                <div class="time">报价期限：{{ x.createTime }}</div>
                <div class="card-state"
                     :style="{background:(x.quotedPriceStatus == 0 ? '#FFCB00' : x.quotedPriceStatus === 1 ? '#ACDC77' : '#C9C9C9')}">
                  {{ x.quotedPriceStatus == 0 ? '未报价' : x.quotedPriceStatus === 1 ? '已报价' : '报价结束' }}
                </div>
              </div>
              <div class="stoneCon">项目石种：
                <span v-for="(iName,indexItem) in x.itemNames" :key="indexItem">{{ iName }}</span>
              </div>
            </div>
          </van-list>
          <div class="no-data" v-if="!isLoading && list.length ==0 ">
            <img :src="noData" alt="">
            暂无数据
          </div>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
    <!--    <vue-seamless-scroll :data="rowsContent" :class-option="optionLeft" class="notice-wrap">-->
    <!--            <span class="notice-span" v-for="(item,index) in rowsContent" :key="index">-->
    <!--                <a :href="item.url">{{ item.title }}</a>-->
    <!--            </span>-->
    <!--    </vue-seamless-scroll >-->
  </div>
</template>

<script scope>
import {onMounted, reactive, toRefs} from "vue";
import {selectEndInquiry} from '@/api/quitedPrice'
import {useRouter} from 'vue-router'

export default {
  name: "quotationList",
  setup() {
    const router = useRouter();
    const state = reactive({
      tabTitle: ['全部', '未报价', '已报价', '报价结束',], //  tab栏标题
      // api: new ApiQuotation(),
      // iconPath: require('../../../../static/image/Wedges-3s-200px.svg'),
      noData: require("@/assets/quotedPrice/none@2x.png"),
      query: {
        pageIndex: 1,
        pageSize: 10,
        quotedPriceStatus: 10000
      },
      total: 10,
      list: [],
      isLoading: false,
      canLoadMore: true,  //  是否有更多数据
      isError: false,
      moreLoading: false,
      moreFinished: false,
      refreshing: false,
    })
    const tabChange = (name,title) =>{
      // console.log(name,title)
      state.list = []
      if (title == '全部') {
        state.query.quotedPriceStatus = 10000
      } else if (title == '未报价') {
        state.query.quotedPriceStatus = 0
      } else if (title == '已报价') {
        state.query.quotedPriceStatus = 1
      } else {
        state.query.quotedPriceStatus = 2
      }
      state.query.pageIndex = 1
      state.moreLoading = false
      state.moreFinished = false
      // this.canLoadMore = true
      getList()
    }
    const getList = ()=>{
      selectEndInquiry(state.query).then(res => {
        state.list = res.data.records
        state.total = res.data.total
        state.moreLoading = false
        if (state.list.length >= res.data.total) {
          state.moreFinished = true
        }
        state.refreshing = false
      }).catch(err => {
        console.log(err)
        state.moreLoading = false
        state.refreshing = false
      })
    }
    const goDetail = (val) => {
      // console.log(val)
      router.push({
        path: '/quotationDetails',
        query: {
          endInquiryId: val.endInquiryId,
        }
      })
    }
    const onRefresh = (title) => {
      // state.list = []
      console.log(title)
      if (title == '全部') {
        state.query.quotedPriceStatus = 10000
      } else if (title == '未报价') {
        state.query.quotedPriceStatus = 0
      } else if (title == '已报价') {
        state.query.quotedPriceStatus = 1
      } else {
        state.query.quotedPriceStatus = 2
      }
      state.query.pageIndex = 1
      state.moreLoading = true
      state.moreFinished = false
      getList()
    }
    const onLoad = (title) => {
      console.log(title)
      if (title == '全部') {
        state.query.quotedPriceStatus = 10000
      } else if (title == '未报价') {
        state.query.quotedPriceStatus = 0
      } else if (title == '已报价') {
        state.query.quotedPriceStatus = 1
      } else {
        state.query.quotedPriceStatus = 2
      }
      if (state.list.length < state.total) {
        state.query.pageIndex += 1
        selectEndInquiry(state.query).then(res => {
          state.list.push(...res.data.records)
          console.log(state.list)
          state.moreLoading = false
          // if ( res.data.records.length < state.query.pageSize) {
          //   state.moreFinished = true
          // }
        }).catch(err => {
          console.log(err)
        })
      } else {
        state.moreLoading = false
        state.moreFinished = true
      }
    }
    const goBack = () => {
      router.push('/')
    }
    onMounted(() => {
      window.document.title = '我的报价书';
      getList()
    })
    return {
      onRefresh,
      goBack,
      tabChange,
      getList,
      goDetail,
      onLoad,
      ...toRefs(state),
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.van-tabs__line {
  bottom: 7px !important;
}

/deep/.van-tabs__nav--line {
  padding-bottom: 0;
}

html, body {
  width: 100%;
  height: 100%;
}

#quotation-list {
  width: 100vw;
  height: 100vh;
  background: #F8F8F9;
  //position: relative;
}

.card {
  width: 100%;
  min-height: 0.8rem;
  box-sizing: border-box;
  padding: 0.16rem;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // align-items: flex-end;
  margin-bottom: 0.08rem;

  .card-info {
    width: 100%;

    .name {
      font-size: 0.15rem;
      font-weight: bold;
      color: #333333;

    }
  }

  .card-foot {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;

    .time {
      font-size: 0.14rem;
      color: #666666;
    }
    .card-state {
      padding: 0.04rem 0.12rem;
      font-size: 0.14rem;
      font-weight: bold;
      color: #FFFFFF;
      background: #FFCB00;
      border-radius: 0.05rem;
      width: fit-content;
      height: 0.2rem;
      line-height: 0.2rem;
    }
  }

  .stoneCon {
    margin-top: 0.08rem;
    font-size: 0.14rem;
    color: #666666;

    span {
      margin-right: 0.04rem;
    }
  }
}

.no-data {
  width: 100%;
  //margin-top: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.14rem;
  font-weight: 400;
  color: #969799;

  img {
    margin-top: 20vh;
    width: 66%;
    height: auto;
  }
}
</style>
